import { Component } from 'react';
import Loader from '../components/loader.js'
import ResponsiveTable from '../components/table.js'
// graphing
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// https://stackoverflow.com/questions/39435395/reactjs-how-to-determine-if-the-application-is-being-viewed-on-mobile-or-deskto
import { isMobile } from 'react-device-detect';
// import { useMediaQuery } from 'react-responsive';

// const useMobileMediaQuery = () => useMediaQuery({ query: "(min-width: 760px)" });

var printf = require('printf');

/* This version of the function breaks the site on Chrome and Safari on iOS because
 * those browsers do not support negative look behind/ahead which causes you to see
 * just a blank white screen.
 * 
 * https://stackoverflow.com/questions/51568821/works-in-chrome-but-breaks-in-safari-invalid-regular-expression-invalid-group
 */
// function numberWithCommas(x) {
//    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
// }

const accounts = [
	{
		 "name": "ADA Wallet",
		 "currency": {
			  "code": "ADA",
			  "name": "Cardano",
			  "color": "#0033AD",
			  "sort_index": 164,
			  "exponent": 6,
			  "type": "crypto",
			  "address_regex": "(^(addr)1[ac-hj-np-z02-9]{6,}$)|(^(DdzFFz|Ae2td)[1-9A-HJ-NP-Za-km-z]+)",
			  "asset_id": "63062039-7afb-56ff-8e19-5e3215dc404a",
			  "slug": "cardano"
		 },
		 "quantity": 142.11999,
		 "positionDollarValue": 155.862993033,
		 "positionCost": 192.02433208999997,
		 "totalCost": 200,
		 "average": 1.3511423135478688,
		 "currentPrice": 1.0967,
		 "profitLoss": -36.161339056999985,
		 "profitLossPercent": -18.080669528499993
	},
	{
		 "name": "BTC Wallet",
		 "currency": {
			  "code": "BTC",
			  "name": "Bitcoin",
			  "color": "#F7931A",
			  "sort_index": 100,
			  "exponent": 8,
			  "type": "crypto",
			  "address_regex": "^([13][a-km-zA-HJ-NP-Z1-9]{25,34})|^(bc1([qpzry9x8gf2tvdw0s3jn54khce6mua7l]{39}|[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{59}))$",
			  "asset_id": "5b71fc48-3dd3-540c-809b-f8c94d0e68b5",
			  "slug": "bitcoin"
		 },
		 "quantity": 0.0024667,
		 "positionDollarValue": 87.05600975,
		 "positionCost": 96.02000885140001,
		 "totalCost": 100,
		 "average": 40422.83955535723,
		 "currentPrice": 35292.5,
		 "profitLoss": -12.655008581199688,
		 "profitLossPercent": -12.65500858119969
	},
	{
		 "name": "ENJ Wallet",
		 "currency": {
			  "code": "ENJ",
			  "name": "Enjin Coin",
			  "color": "#7866D5",
			  "sort_index": 138,
			  "exponent": 8,
			  "type": "crypto",
			  "address_regex": "^(?:0x)?[0-9a-fA-F]{40}$",
			  "asset_id": "46d22e0a-0201-5945-8728-fae2410951e8",
			  "slug": "enjin-coin"
		 },
		 "quantity": 6.27740643,
		 "positionDollarValue": 9.918302159400001,
		 "positionCost": 18.5183489685,
		 "totalCost": 20,
		 "average": 2.9499999999999997,
		 "currentPrice": 1.58,
		 "profitLoss": -8.600046809099998,
		 "profitLossPercent": -43.000234045499994
	},
	{
		 "name": "SHIB Wallet",
		 "currency": {
			  "code": "SHIB",
			  "name": "Shiba Inu",
			  "color": "#1C2951",
			  "sort_index": 183,
			  "exponent": 8,
			  "type": "crypto",
			  "address_regex": "^(?:0x)?[0-9a-fA-F]{40}$",
			  "asset_id": "d6031388-71ab-59c7-8a15-a56ec20d6080",
			  "slug": "shiba-inu"
		 },
		 "quantity": 612542.53520673,
		 "positionDollarValue": 13.230918760465368,
		 "positionCost": 36.52937229894077,
		 "totalCost": 40,
		 "average": 0.00005963565009671091,
		 "currentPrice": 0.0000216,
		 "profitLoss": -23.298453538475403,
		 "profitLossPercent": -58.246133846188506
	},
	{
		 "name": "ETH Wallet",
		 "currency": {
			  "code": "ETH",
			  "name": "Ethereum",
			  "color": "#627EEA",
			  "sort_index": 102,
			  "exponent": 8,
			  "type": "crypto",
			  "address_regex": "^(?:0x)?[0-9a-fA-F]{40}$",
			  "asset_id": "d85dce9b-5b73-5c3c-8978-522ce1d1c1b4",
			  "slug": "ethereum"
		 },
		 "quantity": 0.06239078,
		 "positionDollarValue": 151.7930242932,
		 "positionCost": 193.0300956248,
		 "totalCost": 200,
		 "average": 3093.8881614366737,
		 "currentPrice": 2432.94,
		 "profitLoss": -41.23707133159999,
		 "profitLossPercent": -20.618535665799996
	}
];

function numberWithCommas(x) {
	var parts = x.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
}

function formatNumber(num, decimalPlaces) {
	if (typeof num !== 'number')
		 num = parseFloat(num);

	// if (num < 0.01)
	// 	 return num.toFixed(decimalPlaces ? decimalPlaces : 7);
	// else {
		if (!decimalPlaces) {
			if (num < 0.01) {
				// https://stackoverflow.com/a/23887837
				decimalPlaces = Math.max((1 - Math.floor(Math.log(num) / Math.log(10))) + 1, 2);
			}
			else {
				decimalPlaces = 2;
			}	
		}
		
		// round number to decimal place specified
		const multiplier = Math.pow(10, decimalPlaces);
		let value = Math.round(num * multiplier) / multiplier;

		// make sure number has correct number of decimal places
		value = value.toFixed(decimalPlaces);

		return numberWithCommas(value);
	// }
}

function numberFormatter(value, formatterParams) {
	return (formatterParams.prefix || '') + formatNumber(value, formatterParams.decimalPlaces) + (formatterParams.suffix || '');
}

function currencyFormatter(value, formatterParams) {
	formatterParams.prefix = formatterParams.prefix || "$";
	let formattedValue;

	if (formatterParams.sign) {
		formattedValue = formatNumber(Math.abs(value), formatterParams.decimalPlaces);
		formattedValue = printf("%s$%s", (value < 0 ? '-' : '+'), formattedValue);
	}
	else {
		formattedValue = formatterParams.prefix + formatNumber(value, formatterParams.decimalPlaces);
	}
	return formattedValue;
}

function percentFormatter(value, formatterParams) {
	formatterParams.suffix = formatterParams.suffix || "%";
	formatterParams.decimalPlaces = 2;
	return numberFormatter(value, formatterParams);
}

// headerFilter:true
// TODO - some of this number formatting is already done on the server side
// only thing that's not is that there are no commas since the server returns
// numbers instead of strings
const columnDefs = [
	{title:"Currency", field:"currency.code", minWidth: Math.min(window.innerWidth, 330), responsive: 0}, //never hide this column
	{title:"Quantity", field:"quantity", minWidth: 28, formatter: numberFormatter},
	{title:"Position", field:"positionDollarValue", minWidth: 100, formatter: currencyFormatter},
	{title:"Cost", field:"positionCost", minWidth: 100, formatter: currencyFormatter},
	{title:"Fees", field:"fees", minWidth: 100, formatter: currencyFormatter},
	{title:"Total Cost", field:"totalCost", minWidth: 100, formatter: currencyFormatter},
	{title:"Current Price", field:"currentPrice", minWidth: 100, formatter: currencyFormatter},
	{title:"Average", field:"average", minWidth: 100, formatter: currencyFormatter},
	{title:"P&L", field:"profitLoss", minWidth: 100, formatter: currencyFormatter, formatterParams: {decimalPlaces: 2, sign: true}},
	{title:"P&L %", field:"profitLossPercent", minWidth: 100, formatter: percentFormatter}
];

// const columns = [
//    {
//        name: 'Currency',
//        selector: row => row.currency.code,
//        sortable: true,
//    },
//    {
//        name: 'Coins',
//        selector: row => row.quantity,
//        sortable: true,
//        format: row => formatNumber(row.quantity),
//       //  sortFunction: numericSort
//    },
//    {
//        name: 'Position',
//        selector: row => row.positionDollarValue,
//        sortable: true,
//        format: row => {
//           return formatNumber(row.positionDollarValue);
//        },
//    },
//    {
//        name: 'Cost',
//        selector: row => row.totalCost,
//        sortable: true,
//    },
//    {
//        name: 'Average',
//        selector: row => row.average,
//        sortable: true,
//        format: row => formatNumber(row.average),
//    },
//    {
//        name: 'Price',
//        selector: row => row.currentPrice,
//        sortable: true,
//        format: row => formatNumber(row.currentPrice),
//    },
//    {
//       name: 'P&L',
//       selector: row => row.profitLoss,
//       sortable: true,
//       format: row => {
//          return printf("%s$%s", (row.profitLoss < 0 ? '-' : '+'), formatNumber(Math.abs(row.profitLoss), 2));
//       },
//       conditionalCellStyles: [
//          {
//             when: row => row.profitLoss > 0,
//             classNames: ['green']
//          },
//          {
//             when: row => row.profitLoss < 0,
//             classNames: ['red']
//          }
//       ]
//    },
//    {
//       name: 'P&L %',
//       selector: row => row.profitLossPercent,
//       sortable: true,
//       format: row => {
//          return printf("%s%", formatNumber(row.profitLossPercent, 2));
//       },
//       conditionalCellStyles: [
//          {
//             when: row => row.profitLoss > 0,
//             classNames: ['green']
//          },
//          {
//             when: row => row.profitLoss < 0,
//             classNames: ['red']
//          }
//       ]
//    }
// ];

// const conditionalRowStyles = [
//    {
//       when: row => row.profitLoss > 0,
//       classNames: ['green']
//    },
//    {
//       when: row => row.profitLoss < 0,
//       classNames: ['red']
//    }
// ];

class Accounts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			isLoaded: false,
			accounts: [],
			sort: "positionDollarValue"
		};
	}

	sortAccounts = (event) => {
		const sort = event.target.value;
		console.log("sortAccounts(" + sort + ")");
		
		// sort accounts from largest position to smallest position
		// so that the order in the graph is always the same
		this.state.accounts.sort((a, b) => a[sort] < b[sort] ? 1 : -1);

		this.setState({
			sort: sort
		});
	}

	componentDidMount() {
		// avoid Coinbase API in development mode
		// to speed up repetitive testing and avoid API limits
		if ('a' === 'http://localhost:9000') {
			this.setState({
				isLoaded: true,
				accounts: accounts
			});

			// sort accounts from largest position to smallest position
			// so that the order in the graph is always the same
			accounts.sort((a, b) => a.positionDollarValue < b.positionDollarValue ? 1 : -1);
		}
		// call Coinbase API in production mode
		else {
			fetch('/api/accounts')
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error('Failed to fetch accounts');
				}
			})
			.then((result) => {
				// add extra fields to each account object for charting purposes
				// since the stacked bar chart will sum positionDollarValue and positionCost
				// which is not what we want
				// result.forEach(function(account) {
				// 	account.lowerValue = Math.min(account.positionCost, account.positionDollarValue);
				// 	account.upperValue = Math.abs(account.positionDollarValue - account.positionCost);
				// });

				const accounts = result.sort((a, b) => a.positionDollarValue < b.positionDollarValue ? 1 : -1);

				this.setState({
					isLoaded: true,
					accounts: accounts
				});
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					isLoaded: true,
					error: error
				});
			});
		}	
	}

	getCellClassName = (account, column) => {
		let className = "";
		
		if (isMobile && column.title === "Currency")
			className = "h5";

		// https://getbootstrap.com/docs/5.0/content/tables/
		if (column.title.includes("P&L")) {
			if (account.positionDollarValue > account.positionCost) {
				className = "table-success";
			}
			else if (account.positionDollarValue < account.positionCost) {
				className = "table-danger";
			}

		}
		return className;
	}

	render() {
		let { error, isLoaded, accounts } = this.state;

		// debug
		// isLoaded = false;

		if (error) {
			return <div className="text-center">Error: {error.message}. <a href="/accounts">Reload this page</a>.</div>;
		} else if (!isLoaded) {
			return (
				<Loader message="Fetching accounts..." />
			);	
		} else {
			/*
			==============================
			ADA Wallet
			==============================
					Coins:  64.93
				Position:  $128.32
					 Cost:  $100
				 Average:  $1.48
					Price:  $1.98
					  P&L: +$32.39 (32.39%)
			*/

			let totalProfitLoss = 0;
			let totalCost = 0;
			let totalFees = 0;
			let totalPosition = 0;
			let totalProfitLossPercent = 0;

			accounts.forEach(function(account) {
				 totalProfitLoss += account.profitLoss;
				 totalCost += account.totalCost;
				 totalPosition += account.positionDollarValue;
				 totalFees += account.fees;
				 // TODO - calculate fees
			});

			totalProfitLossPercent = (totalProfitLoss / totalCost) * 100;

			// console.log("render() - isLoaded:", isLoaded, "accounts:", accounts);

			return (
				<>
					<ResponsiveContainer width="100%" height={isMobile ? (60 * accounts.length) : 300} debounce={1}>
					  <BarChart
						 data={accounts}
						 margin={{
							top: 0,
							right: 0,
							left: -14,
							bottom: 15,
						 }}
						 layout={isMobile ? "vertical" : "horizontal"}
					  >
						<CartesianGrid strokeDasharray="3 3" />
						{isMobile  ? (
							<>
							<YAxis dataKey="currency.code" type="category" />
							<XAxis
								// label="USD"
								type="number"
								tickFormatter={YAxisFormater}
								/>
							</>	
						) : (
							<>
								<XAxis dataKey="currency.code" />
								<YAxis
									// label="USD"
									tickFormatter={YAxisFormater}
									/>
							</>	
						 )}
						 <Tooltip formatter={(value) => new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(value)} />
						 <Legend 
						 	verticalAlign="top"
						 	formatter={renderColorfulLegendText}
							 iconSize={18}
							height={36}
						 />
						 <Bar dataKey="positionCost" name="Position Cost" fill="#83a6ed" />
						 {/* <Bar dataKey="lowerValue" name="Position Cost" fill="#8884d8" stackId="a" />
							<Bar dataKey="upperValue" name="Position Dollar Value" stackId="a"> */}
						 <Bar dataKey="positionDollarValue" name="Position Dollar Value" fill="#82ca9d">
							  {accounts.map((entry, index) => (
									<Cell key={entry.name} fill={entry.positionDollarValue >= entry.positionCost ? '#82ca9d' : '#dc3545' }/>
							  ))}
						 </Bar>
					  </BarChart>
					</ResponsiveContainer>

					<h2>Summary</h2>
					<div className="mt-3 mb-3 p-2">
						<p>
							<strong>Total Position: </strong>
							{printf(" $%s", formatNumber(totalPosition, 2))}
						</p>
						<p>
							<strong>Total Fees: </strong>
							{printf(" $%s", formatNumber(totalFees, 2))}
							<small> (not included in P&L)</small>
						</p>
						<p>
							<strong>Total Cost: </strong>
							{printf(" $%s", formatNumber(totalCost, 2))}
						</p>
						<p>
							<strong>Total P&L: </strong>
							{printf(" %s$%s (%s%)", (totalProfitLoss < 0 ? '-' : '+'), formatNumber(Math.abs(totalProfitLoss), 2), formatNumber(totalProfitLossPercent, 2))}
						</p>
						<p className="mb-0">
							<small><strong>Disclaimer:</strong> These numbers <em>exclude</em> rewards received from Coinbase</small>
						</p>
					</div>

					<h2 className="float-start">Your Assets</h2>
					<div className="float-end mb-2" style={{marginTop: '-9px'}}>
						<select className="form-select" onChange={this.sortAccounts} value={this.state.sort}>
							<option value="positionDollarValue">Balance</option>
							<option value="currentPrice">Price</option>
						</select>
					</div>
					<ResponsiveTable 
						columns={columnDefs} 
						data={accounts} 
						className="table table-striped table-hover table-dark"
						getCellClassName={this.getCellClassName}
					/>
				</>
			);
		}
	}
}

const renderColorfulLegendText = (value, entry) => {
	// const { color } = entry;
	// console.log("value:", value, " entry:", entry);

	let style = {
		padding: '3px'
	};

	if (value.toLowerCase() === 'position dollar value') {
		// style = { backgroundImage: 'linear-gradient(to right, #dc3545, #82ca9d)' };
		// style.backgroundImage = 'linear-gradient(to right, #28a745, #dc3545)';
		style.backgroundImage = 'linear-gradient(to right, #82ca9d, #dc3545)';
		style.color = 'white';
	}	

  return <span style={style}>{value.charAt(0).toUpperCase() + value.slice(1)}</span>;
};

const YAxisFormater = (number) => {
	return '$' + number.toString();
}

export default Accounts;