// import logo from './logo.svg';
import './App.css';
import React from 'react';
import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import { Container } from 'react-bootstrap';
import Home from "./routes/home";
import Accounts from "./routes/accounts";
import AuthContext from './AuthContext';
import Loader from './components/loader.js';
import { slide as Menu } from 'react-burger-menu';

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
			isLoaded: false,
            error: null,
			// isAuthenticated: false,
			user: null
        };
    }

	async componentDidMount() {
        try {
            // const response = await fetch(process.env.REACT_APP_API_PATH + "/auth/login/success");
			const response = await fetch("/api/auth/login/success");
            console.log("/api/auth/login/success");
            // console.log(response);

            if (!response.ok)
                throw Error(response.statusText);

            const data = await response.json();
            console.log(data);
             
            if (response.status === 200) {
				// TODO - store user object in redux store?
                this.setState({
                    user: data.user,
					isLoaded: true,
                    // isAuthenticated: data.isAuthenticated
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

	logoutButtonClick = async (event) => {    
		event.preventDefault();
        try {
            const response = await fetch("/api/auth/logout");
            if (!response.ok)
                throw Error(response.statusText);

            this.setState({
				user: null,
                // isAuthenticated: false
            });

			// remove the user from react context
			// delete this.context.user;
        } catch (error) {
            console.log(error);
        }
    }

	render() {
		let { user, isLoaded } = this.state;

		let landingPage;
		if (isLoaded) {
			if (user) {
				landingPage = <Accounts />;
			}
			else {
				landingPage = <Home />;
			}
		}
		else {
			landingPage = <Loader />;
		}

		return (
			<AuthContext.Provider value={this.state}>
				<div className="App">
					<header className="App-header">
						<h1>Coinbase Insights</h1>
						{/* <img src={logo} className="App-logo" alt="logo" /> */}
						
							<div className="welcome">
								{user ? (
									<>
										<div className="w-100 text-center">
											<h6 className="mb-0">Hello, {user.displayName}</h6>
										</div>
										{/* <a href="/#" className="link-primary float-end me-2" onClick={this.logoutButtonClick}>Sign Out</a> */}
										<Menu right>
											{/* <a id="home" className="menu-item" href="/">Home</a> */}
											{/* <a id="about" className="menu-item" href="/about">About</a>
											<a id="contact" className="menu-item" href="/contact">Contact</a>
											<a onClick={ this.showSettings } className="menu-item--small" href="">Settings</a> */}
											<a href="/#" onClick={this.logoutButtonClick}>Sign Out</a>
										</Menu>
										{/* <div className="me-2 float-end">
											<a href="#" className="link-primary" onClick={this.logoutButtonClick}>Sign Out</a>
										</div> */}
									</>
								) : ''}
							</div>
					</header>
					<main>
						<Container fluid className="App-body">
							<Outlet />
							<Routes>
								<Route path="/" element={landingPage} />
								<Route
									path="/accounts"
									element={
										<RequireAuth>
											<Accounts />
										</RequireAuth>
									}
								/>
								<Route
									path="*"
									element={
										<main style={{ padding: "1rem" }}>
											<p>Page not found</p>
										</main>
									}
								/>
							</Routes>
						</Container>
					</main>
				</div>
			</AuthContext.Provider>
		);
	}
}

// this is only needed by function components
// class components have to use "this.context" instead
function useAuth() {
	return React.useContext(AuthContext);
}

function RequireAuth({ children }) {
	let auth = useAuth();
	let location = useLocation();

	if (!auth.user) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/" state={{ from: location }} />;
	}

	return children;
}

export default App;
