import React, { Component } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

// https://www.geeksforgeeks.org/how-to-build-an-html-table-using-reactjs-from-arrays/


class ResponsiveTable extends Component {
    render() {
        var columns = this.props.columns;
        var data = this.props.data;
        return (
            <Table className={this.props.className}>
                <Thead>
                    <Tr className="table-dark">
                        {columns.map(column => <Th key={column.title}>{column.title}</Th>)}
                    </Tr>
                </Thead>
                <Tbody>
                    {data.map((item, index) => {
                        return (
                            <TableRow 
                                key={item.currency.code} 
                                item={item} 
                                columns={columns}
                                getCellClassName={this.props.getCellClassName}
                            />
                        );
                    })}
                </Tbody>
            </Table>
        );
    }
}

// TODO - add dynamic styling
// https://getbootstrap.com/docs/5.0/content/tables/
class TableRow extends Component {
    render() {
        var item = this.props.item;
        var columns = this.props.columns;
        return (
            <Tr>
                {columns.map((column, index) => {
                    let value;
                    if (!column.field.includes(".")) {
                        value = item[column.field];
                    }    
                    else {
                        value = column.field.split('.').reduce((o, k) => (o || {})[k], item);
                    }

                    // optional column value formatting
                    if (column.formatter) {
                        value = column.formatter(value, column.formatterParams || {});
                    }

                    return <Td key={column.field} className={this.props.getCellClassName ? this.props.getCellClassName(item, column) : ''}>{value}</Td>;
                })}
            </Tr>
        )
    }
}

// class TableCell {
//     constructor(element, value) {
//       this.element = element;
//       this.value = value;
//     }

//     getValue() {
//         return this.value;
//     }
//     getElement() {
//         return this.element;
//     }
// }

export default ResponsiveTable;