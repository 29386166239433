import { Component } from 'react';
import { Button } from 'react-bootstrap';
import AuthContext from '../AuthContext';

class Home extends Component {

    static contextType = AuthContext;

    loginButtonClick = (event) => {
        event.preventDefault();
        window.open(process.env.REACT_APP_API_URI + "/api/auth/coinbase", "_self");
    }

    render() {
        // use AuthContext.Consumer so that any time the auth context changes
        // this component will get re-rendered
        return (
            <AuthContext.Consumer>
              {({ user }) => (
                  <div className="text-center">
                      {/* <h1>Home Page</h1> */}
                      {user ? (
                            // <p>Welcome {user.displayName}!</p>
                            ''
                      ) : (
                        <Button onClick={this.loginButtonClick} size="lg" className="login-button">Log in with Coinbase</Button>

                        // <div className="d-grid gap-2">
                        //   <Button variant="primary" size="lg" onClick={this.loginButtonClick}>
                        //     Log in with Coinbase
                        //   </Button>
                        // </div>
                      )}
                  </div>
              )}
            </AuthContext.Consumer>
        );
    }
}

export default Home;